import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PageLayoutService } from './page-layout.service';
import { Injectable, inject } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class PageResolverService implements Resolve<any> {
  dynamicPageService = inject(PageLayoutService);

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const id = route.paramMap.get('id');
    if (id) {
      return this.dynamicPageService.getPageById(id);
    } else {
      throw new Error('Page ID not provided in route');
    }
  }
}
